import React from "react";
import FadeIn from "../repeating_elements/Fade_In.js";
import Award from "./Award_Single.js";


const Awards = ({ data }) => (
	<div className="grid awards-feature">
		<div className="grid-m-6 grid-t-16 grid-d-14 push-d-1">
			<div className="grid-reset">
				<FadeIn>
					<h3 style={{marginBottom:"50px"}} className="grid-m-6 grid-t-16 grid-d-16 styling-eyebrow default-animation">Recent Awards</h3>
				</FadeIn>
				<FadeIn>
					<div className="row awards-wrap">
						{data.awards_feature.map((award, i) => (
							<Award key={ i } link={ award.link } image={ award.image.sizes.large } title={ award.title } description={ award.description } />
						))}
					</div>
				</FadeIn>
			</div>
		</div>
	</div>
);

export default Awards;
