import React, { Component } from "react";
import Project_Grid_Maker from "./Project-Grid-Maker.js";
import DropDown from "../repeating_elements/Drop_Down.js";


export default class Work_Display extends Component {
	constructor(props){
		super(props);
		this.state = {
			grid_shown: false,
			fade_class: "",
			in_view: false,
			position: null,
			work_grids: [],
			active_grid: false,
			active_grid_type: false,
			active_projects: false
		};
		this.count = 0;
		this.set_active_grid = this.set_active_grid.bind(this);
		this.change_grid = this.change_grid.bind(this);
		this.hide_grid = this.hide_grid.bind(this);
		this.show_grid = this.show_grid.bind(this);
	}
	componentDidMount(){
		this.props.data.forEach(i => {
			var obj = {};
			obj.name = i.industry_name || false;
			obj.projects = [];
			i.projects.forEach(p => {
				obj.projects.push(p.ID);
			})
			this.state.work_grids.push(obj)
		})
		this.set_active_grid("all")

	}
	set_active_grid(name){
		var ind = this.props.data.find(i => {
			return i.industry_name.toLowerCase() === name.toLowerCase()
		})
		this.setState({
			active_grid: ind.industry_name,
			active_grid_type: ind.grid_type,
			active_projects: ind.grid_type === "projects" ? ind.projects : ind.page_links,
		})
	}
	change_grid(name){
		this.hide_grid()
		this.set_active_grid(name)
		this.render()
	}
	hide_grid(){
		this.setState({
			grid_shown: false,
			fade_class: ""
		})
	}
	show_grid(){
		var parent = this;
		this.setState({
			grid_shown: true,
			fade_class: " grid-loaded slick-active"
		})
	}
	render() {
		if(!this.state.grid_shown){
			this.show_grid();
		}
		return(
			<div className="work-display home-work-display grid in-view">
				<div className="work-filter-container grid-m-6 grid-t-8 grid-d-8">
					<DropDown options={this.state.work_grids} active={this.state.active_grid} onChange={this.change_grid} />
				</div>
				<div className={"work-projects-container grid-contain row"+this.state.fade_class}>
					<Project_Grid_Maker projects={this.state.active_projects} type={this.state.active_grid_type} work_posts={this.props.work_posts} />
				</div>
			</div>
		);
	}
}
