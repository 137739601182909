import React, { Component } from "react";

import { decode } from  "../utilities/decode.js";

import ArrowHead from "../../assets/svg/arrow-head.svg";


export default class DropDown extends Component {
	constructor(props){
		super(props);
		this.state = {
			text: "View by industry",
			active_element: "all",
			open_status: false,
			interior_height: 0,
			class_names: " drop-down-closed"
		};
		this.link_list = React.createRef();
		this.toggle_module = this.toggle_module.bind(this);
		this.close_module = this.close_module.bind(this);
		this.open_module = this.open_module.bind(this);
		this.render_list_items = this.render_list_items.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	toggle_module() {
		this.state.open_status ? this.close_module() : this.open_module();
	}
	close_module(){
		this.setState({
			open_status: false,
			interior_height: 0,
			class_names: " drop-down-closed"
		})
	}
	open_module(){
		this.setState({
			open_status: true,
			interior_height: this.link_list.current.offsetHeight,
			class_names: " drop-down-opened"
		})
	}
	handleChange(e){
		this.close_module();
		this.setState({
			text: e,
			active_element: e
		})
		if (this.props.onChange) {
			this.props.onChange(e);
		}
	}
	render_list_items(){
		if(this.props.options){
			var items = this.props.options;
			return items.map((item) =>
			<li key={item.name} className={item.name.toLowerCase() === this.state.active_element.toLowerCase() ? "active" : ""}>
					<button aria-label={`Select ${item.name} filter`} data-value={item.name} onClick={()=>{this.handleChange(item.name)}}></button>
					{decode(item.name)}
				</li>
			);
		}
	}
	render() {
		return <div className={"drop-down"+this.state.class_names}>
					<div className="drop-down-title">
						<button aria-label="Filter by" onClick={this.toggle_module}></button>
						<span className="title">{this.state.text}</span>
						<span className="icon"><ArrowHead /></span>
					</div>
					<div className="drop-down-list-contain" style={{height: this.state.interior_height + "px"}}>
						<ul ref={this.link_list} className="drop-down-list">
							{ this.render_list_items() }
						</ul>
					</div>
				</div>;
	}
}
