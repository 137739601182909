import React from "react";
import Image from "../repeating_elements/Image.js";
import AwardWrapper from "./Award_Single_Outer.js";

const Award = ({ link, image, title, description }) => (
	<AwardWrapper link={link} classes="grid-m-6 grid-t-1-3 grid-d-1-3 award">
		<React.Fragment>
			<div className="image">
				<Image url={image} />
			</div>
			<div className="content">
				<span>{ title }</span>
				<span>{ description }</span>
			</div>
		</React.Fragment>
	</AwardWrapper>
);

export default Award;
