import React from "react";
import Image from "../repeating_elements/Image.js";
import FadeIn from "../repeating_elements/Fade_In.js";
import Client from "./Client_Single.js";


const Clients = ({ data }) =>  ( // pass only relatable data
	<div className="grid clients-feature">
		<div className="grid-m-6 grid-t-16 grid-d-14 push-d-1">
			<div className="grid-reset">
				<FadeIn>
					<h2 className="grid-m-6 grid-t-16 grid-d-16 styling-eyebrow default-animation">Our Clients</h2>
				</FadeIn>
				<FadeIn>
					<div className="row client-wrap">
						{data.clients_feature.map((client, i) => (
							<Client key={i} name={ client.name } logo={ client.logo.sizes.large } />
						))}
					</div>
				</FadeIn>
			</div>
		</div>
	</div>
);

export default Clients;
