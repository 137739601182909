import React from "react"
import Project_Grid from "./Project-Grid.js"

const Project_Grid_Maker = ({ projects, type, per_grid, work_posts }) => {
	const number_of_grids = Math.floor(projects.length / per_grid)
	const grids = []
	let count = 0
	for (let i = 0; i < number_of_grids; i++) {
		const first_project = projects[count++]
		const second_project = projects[count++]
		const third_project = projects[count++]
		grids.push(
			<Project_Grid
				key={count}
				type={type}
				project1={first_project}
				project2={second_project}
				project3={third_project}
				work_posts={work_posts}
			/>
		)
	}
	return <React.Fragment>{grids}</React.Fragment>
}

Project_Grid_Maker.defaultProps = {
	per_grid: 3,
}

export default Project_Grid_Maker
