import React from "react"
import ProjectItem from "./Project_Item.js"
import format_project_data from "./format_project.js"

const Project_Grid = ({ project1, project2, project3, type, work_posts }) => (
	<div className="slide row work-display-grid">
		<div className="project-column grid-m-6 grid-t-8 grid-d-8">
			<ProjectItem
				key={"second-work-display-1"}
				project={format_project_data(project1, type, work_posts)}
				tall={true}
				classes="project-list-1"
			/>
		</div>
		<div className="project-column grid-m-6 grid-t-8 grid-d-8">
			<ProjectItem
				key={"second-work-display-2"}
				project={format_project_data(project2, type, work_posts)}
				classes="project-list-2"
			/>
			<ProjectItem
				key={"second-work-display-3"}
				project={format_project_data(project3, type, work_posts)}
				classes="project-list-3"
			/>
		</div>
	</div>
)

export default Project_Grid
