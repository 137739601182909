function format_project_data(project, type, work_posts){
	let optionLink = false;
	let post_data = false;
	let final_data = {};
	if(type == "projects") {
		for(const post of work_posts){
			if(post.id == project.ID) {
				post_data = post;
			}
		}
	}

	if(project.case_study) {
		optionLink = project.case_study.replace('https://api.thecharlesnyc.com', '');
	} else if(project.category) {
		optionLink = "/work?industry=" + project.category[0].slug;
	}
	if(post_data) {
		final_data.display = {
			thumbnail: post_data.acf.thumbnail,
			thumbnailTall: post_data.acf.tall_thumbnail || post_data.acf.thumbnail,
			thumbnailWide: post_data.acf.wide_thumbnail || post_data.acf.thumbnail,
			video: post_data.acf.v_thumbnail,
			videoTall: post_data.acf.v_tall_thumbnail || post_data.acf.v_thumbnail,
			videoWide: post_data.acf.v_wide_thumbnail || post_data.acf.v_thumbnail
		};
		final_data.options = {
			lock: false,
			linkLink: false,
			link: post_data.link.replace('https://api.thecharlesnyc.com', '')
		};
		final_data.copy = {
			title: post_data.title.rendered,
			summary: false,
		};
	} else {
		final_data.display = {
			overrideTextColor: project.color_override,
			projectColor: project.background_color,
			textColor: project.text_color,
			thumbnail: project.thumbnail,
			thumbnailTall: project.tall_thumbnail || project.thumbnail,
			thumbnailWide: project.wide_thumbnail || project.thumbnail,
			video: project.v_thumbnail,
			videoTall: project.v_tall_thumbnail || project.v_thumbnail,
			videoWide: project.v_wide_thumbnail || project.v_thumbnail
		};
		final_data.options = {
			lock: false,
			linkLink: false,
			link: optionLink
		};
		final_data.copy = {
			title: project.name,
			summary: project.summary,
		};
	}
	return final_data;
}

export default format_project_data;
