import React from "react";
import { Link } from 'gatsby';
import { ArrowLink } from "../buttons/Arrow_Link.js";

const MissionStatement = ({data}) => (
	<div className="mission-statement grid">
		<div id="start-content"></div>
		<div className="interior-mission-statement grid-m-6 grid-t-16 push-d-1 grid-d-14">
			<h1 className="styling-eyebrow">{ data[0].acf["mission-statement-title"] }</h1>
			<div className="content-contain">
				<h3 className="styling-header-1">A full service agency focused on <span><Link to="/services/strategy" title="Go to Strategy service page">Strategy</Link>,</span>  <span><Link to="/services/media" title="Go to Media service page">Media</Link>,</span>  <span><Link to="/services/design" title="Go to Design service page">Design</Link>,</span> <span><Link to="/services/content" title="Go to Content service page">Content</Link>,</span> and <Link to="/services/technology" title="Go to Technology service page">Technology</Link></h3>
				<div>
					<ArrowLink title={ data[0].acf["mission-statement-cta"] } url="/services/" />
				</div>
			</div>
		</div>
	</div>
);

export default MissionStatement;
