import React from "react"
import { Link } from "gatsby"
import truncate_string from "../utilities/truncate_string.js"
import BackgroundImage from "../repeating_elements/Background-Image.js"
import FadeIn from "../repeating_elements/Fade_In.js"
import BrowserView from "../utilities/mobileViewCheck.js"

const ProjectItem = ({ project, tall, classes }) => {
	let thumbnail = project.display.thumbnail
	let thumbnail_video = false
	let extra_classes = ""

	// get image size
	if (tall === true) {
		thumbnail = project.display.thumbnailTall || thumbnail
		extra_classes += "tall-item "
	}

	// get thumbnail size
	if (tall === true && project.display.videoTall) {
		thumbnail_video = project.display.videoTall
	} else if (project.display.video) {
		thumbnail_video = project.display.video
	}

	return (
		<FadeIn>
			<div className={"project-item " + extra_classes + classes}>
				{project.options.lock === "external_link" ? (
					<a
						href={project.options.linkLink}
						title="See project"
						target="_blank"
						rel="noopener noreferrer"
					>
						{" "}
					</a>
				) : (
					<Link to={project.options.link} title="See project"></Link>
				)}
				<div className="item-imagery">
					<div className="fill"></div>
					<div className="thumbnail">
						<BrowserView video={thumbnail_video} key={thumbnail_video} />
						<BackgroundImage url={project.display.thumbnail.sizes.large} />
					</div>
				</div>
				<div className="item-content">
					{project.copy.title ? (
						<p
							className="title"
							dangerouslySetInnerHTML={{ __html: project.copy.title }}
						/>
					) : (
						""
					)}
					{project.copy.summary ? (
						<p className="description">
							{truncate_string(project.copy.summary)}
						</p>
					) : (
						""
					)}
					<div className="but">Discover ⟶</div>
				</div>
			</div>
		</FadeIn>
	)
}

export default ProjectItem
