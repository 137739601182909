import React from "react";
import {BodyCopy} from "../repeating_elements/Typography.js";

const PressSmall = ({ link, title, description }) => (
	<div className="press-item">
		<BodyCopy>
			<p className="body-copy"><a href={link}>{description}</a></p>
		</BodyCopy>
	</div>
);

export default PressSmall;
