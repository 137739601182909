// this is a duplicate of ./Lazy_Load.js. Since we added react lazy load, the previous naming convention is confusing.
// This has been more approaitely named. ./Lazy_Load.js will be phased out and deleted from the project after refactoring.

import React, { Component } from "react";
import 'intersection-observer';
import { InView } from 'react-intersection-observer';

export default class FadeIn extends Component {
	constructor(props){
		super(props);
		this.state = {
			inViewClass: ""
		};
	}
	handleInView = (inView) => {
		if (inView) {
			this.setState({
				inViewClass: " in-view"
			})
		}
	}
	render() {
		return <InView as="div" triggerOnce={true} rootMargin="0% 0% -10%" onChange={inView => this.handleInView(inView)} className={"lazy-load-group" + this.state.inViewClass}>
			{this.props.children}
		</InView>
	}
};
