import React from "react";
import Image from "../repeating_elements/Image.js";

const AwardWrapper = ({ link, classes, children }) => {
	if ( link != "" ) {
		return <a href={link} className={classes}>
			{children}
		</a>;
	}
	return <div className={classes}>
		{children}
	</div>;
};

export default AwardWrapper;
