import React from "react";
import BackgroundImage from "../repeating_elements/Background-Image.js";
import {BodyCopy} from "../repeating_elements/Typography.js";

const PressLarge = ({ link, image, title, description }) => (
	<div className="grid-m-6 grid-t-5 grid-d-5 press-item">
		<a href={link.replace('api.thecharlesnyc.com', 'thecharlesnyc.com')} title={title}>
			<div className="img">
				<BackgroundImage url={image} />
			</div>
			<BodyCopy >
				<p className="title">{title}</p>
			</BodyCopy>
		</a>
	</div>
);

export default PressLarge;
