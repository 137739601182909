import React from "react";
import FadeIn from "../repeating_elements/Fade_In.js";
import PressLarge from "./Press_Large.js";
import PressSmall from "./Press_Small.js";




const Press = ({ data }) => (
	<div className="grid awards-feature">
		<div className="grid-m-6 grid-t-16 grid-d-14 push-d-1">
			<div className="grid-reset">
				<FadeIn>
					<h3 style={{marginBottom:"50px"}} className="grid-m-6 grid-t-16 grid-d-16 styling-eyebrow default-animation">Recent Announcements</h3>
				</FadeIn>
				<FadeIn>
					<div className="row press-grid">
						{data.journal_items.map( ( press, i ) => (
							<PressLarge key={i} link={press.link} image={press.image} title={press.title} description={press.description} />
						))}
						<div className="grid-m-6 grid-t-6 grid-d-6 press-col">
							{data.updates_items.map( ( press, i ) => (
								<PressSmall key={i} link={press.link} title={press.title} description={press.description} />
							))}
						</div>
					</div>
				</FadeIn>
			</div>
		</div>
	</div>
);

export default Press;
