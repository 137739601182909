import React from "react"
import { ArrowLink } from "../buttons/Arrow_Link.js"
import JaneLogo from "../../assets/svg/jane-logo.svg"
import FadeIn from "../repeating_elements/Fade_In.js"
import BackgroundImage from "../repeating_elements/Background-Image.js"
import BackgroundVideo from "../repeating_elements/background_video.js"

const JaneCallout = ({ data }) => {
	return (
		<FadeIn>
			<div className="jane-feature-container">
				<div className="grid">
					<div className="jane-feature grid-m-6 grid-t-16 grid-d-16">
						<div className="image-bg">
							<div className="desktop">
								<a
									href="https://www.linkedin.com/jobs/the-charles-jobs-worldwide?f_C=2684553&trk=top-card_top-card-primary-button-top-card-primary-cta&position=1&pageNum=0"
									target="_blank"
									rel="noopener noreferrer"
									title="Go to Careers"
								>
									<BackgroundImage
										url={data.jane_desktop_image.sizes.large}
										alt="Careers"
										loading="eager"
									/>
									<BackgroundVideo videoUrl={data.video_desktop} />
								</a>
							</div>
							<div className="mobile">
								<a
									href="https://www.linkedin.com/jobs/the-charles-jobs-worldwide?f_C=2684553&trk=top-card_top-card-primary-button-top-card-primary-cta&position=1&pageNum=0"
									target="_blank"
									rel="noopener noreferrer"
									title="Go to Careers"
								>
									<BackgroundImage
										url={data.jane_mobile_image.sizes.large}
										alt="Careers"
										loading="lazy"
									/>
									<BackgroundVideo videoUrl={data.video_mobile} />
								</a>
							</div>
						</div>
						<div className="position-reset">
							<div className="content">
								<JaneLogo />
								<p>{data.jane_blurb}</p>
								<ArrowLink title={data.jane_cta} url={data.jane_url} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</FadeIn>
	)
}

export default JaneCallout
