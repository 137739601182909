import React from "react";
import Image from "../repeating_elements/Image.js";

const Client = ({ name, logo }) => (
	<div className="grid-m-3 grid-t-1-5 grid-d-1-5 client">
		<Image url={logo} alt={name} />
	</div>
);

export default Client;
