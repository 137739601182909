import React from "react"
import { Link } from "gatsby"
import { ArrowLink } from "../components/buttons/Arrow_Link.js"
import { ParallaxProvider } from "react-scroll-parallax"
import PageTransition from "gatsby-plugin-page-transitions"
import Navigation from "../components/Header/Navigation.js"
import PageBreak from "../components/repeating_elements/page_break.js"
import PanoramicHero from "../components/Global/Panoramic_Hero.js"
import WorkDisplay from "../components/Homepage/Work_Display.js"
import JaneCallout from "../components/Homepage/Jane_Callout.js"
import Clients from "../components/Homepage/Clients.js"
import MissionStatement from "../components/Homepage/Mission_Statement.js"
import Press from "../components/Homepage/Press.js"
import Awards from "../components/Homepage/Awards.js"
import Footer from "../components/Footer/Footer.js"
import WorkWithUs from "../components/Global/Work_With_Us_Button.js"
import GDPR from "../components/Global/GDPR"
import SEO from "../components/seo.js"

const IndexPage = props => {
	return (
		<ParallaxProvider>
			<PageTransition>
				<GDPR data={props.pageContext.cookieBanner} />
				<SEO
					title={props.pageContext.data[0].yoast_meta.yoast_wpseo_title}
					description={
						props.pageContext.data[0].yoast_meta.yoast_wpseo_metadesc
					}
				/>
				<div className="site-contain">
					<Navigation links={props.pageContext.header} />
					<div className="page-main">
						<WorkWithUs />
						<div className={"page home-page page-ready page-loaded show-text"}>
							<PanoramicHero slides={props.pageContext.data} />
							<MissionStatement data={props.pageContext.data} />
							<WorkDisplay
								data={props.pageContext.data[0].acf.industry_grid}
								work_posts={props.pageContext.industryPosts}
							/>
							<PageBreak />
							<div className="grid">
								<div className="grid-m-6 grid-t-16 grid-d-14 push-d-1">
									<div className="grid-reset">
										<div className="grid-d-16">
											<h2 className="styling-header-1">
												Want to{" "}
												<Link
													to="/contact/new-business"
													title="Start a project"
												>
													create
												</Link>
												<br /> something beautiful?
											</h2>
											<br></br>
											<ArrowLink
												title="New business"
												url="/contact/new-business"
											/>
											<span
												style={{ display: "inline-block", width: "60px" }}
											></span>
											<ArrowLink
												title="Careers"
												color="white"
												url="https://www.linkedin.com/jobs/the-charles-jobs-worldwide?f_C=2684553&trk=top-card_top-card-primary-button-top-card-primary-cta&position=1&pageNum=0"
											/>
										</div>
									</div>
								</div>
							</div>
							<PageBreak />
							<Clients data={props.pageContext.data[0].acf} />
							<PageBreak />
							<Press data={props.pageContext.data[0].acf} />
							<PageBreak />
							<Awards data={props.pageContext.data[0].acf} />
							<PageBreak />
							<JaneCallout data={props.pageContext.data[0].acf} />
							<Footer links={props.pageContext.footer} />
						</div>
					</div>
				</div>
			</PageTransition>
		</ParallaxProvider>
	)
}

export default IndexPage
